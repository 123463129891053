@tailwind base;

@layer base {

    body {
        background-color: #F1F1F1;
    }

    h2 {
        @apply font-bold;
    }
    h3 {
        @apply font-medium;
    }

    .btn {
        @apply inline-block rounded-md text-center self-center;
    }

    .btn-base {
        @apply text-lg font-semibold py-2 px-4;
    }

    .btn-xl {
        @apply text-xl py-2 px-3 w-full;
    }

    .btn-primary {
        @apply text-white bg-primary-600;
    }

    .btn-success {
        @apply text-white bg-primary-300;
    }

    .btn-invalid {
        @apply text-white bg-primary-400;
    }

    .btn-verifying {
        @apply text-white bg-primary-900;
    }

}

@tailwind components;

@tailwind utilities;

@layer components {
    .darkBg {
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: hidden;
    }

}
